import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Carousel } from "react-bootstrap";
import styles from "./MainCarousel.module.css";
import { ButtonPrimary } from "../../Buttons";

const MainCarousel = ({ content, button, lang }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "baners" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  );
  const carouselProps = { indicators: false, fade: true, pauseOnHover: false };

  const [width, setWidth] = useState(0);
  const isMobile = width < 1024;
  const refCallback = element => {
    if (element) {
      setWidth(element.getBoundingClientRect().width);
    }
  };

  return (
    <section ref={refCallback}>
      <Carousel {...carouselProps} className={styles.carousel}>
        {allFile.edges
          .slice(isMobile ? 0 : 4, isMobile ? 4 : 8)
          .map((slide, index) => {
            const { node } = slide;
            return (
              <Carousel.Item
                key={`${node.id}`}
                className={styles.item}
                style={{
                  backgroundImage: `url(${node.publicURL})`
                }}
              >
                <Carousel.Caption className={styles.caption}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <ButtonPrimary
                    variant="outline-primary"
                    to="products"
                    language={lang}
                  >
                    {button}
                  </ButtonPrimary>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </section>
  );
};

export default MainCarousel;
