export default {
  en: {
    mainSection: {
      title1: "Welcome",
      title2: "to Fastpack!",
      text:
        "We manufacture custom paper and cardboard packaging with print for gastronomy. If you are looking for take-away packaging for your restaurant, bar, food truck or cafe, then you are in the right place. ",
      button: "See our offer"
    },
    secondSection: {
      title1: "It's only packaging...",
      title2: "or maybe something more?",
      text1a:
        "Do you know how customers perceive your brand through the prism of packaging? You just need to look at the leading global brands to realise, how important is a seemingly insignificant packaging as an element of the strategy.",
      text1b:
        "A new pop culture is also forming, which made information reach almost every corner of the planet at the speed of light. Smartphones have taken over our lives and accompany us every day.",
      text2a:
        "We are eager to share opinions, photos and hundreds of different types of information with the whole world. Almost everyone is present on Facebook, Instagram and other social media sites.",
      text2b:
        "Modern consumer perceives products and services through the prism of this information. Online references and recommendations are currently one of the most powerful marketing tools.",
      text2c:
        "That's why it's so important to positively surprise and interest the client, build a long-term relationship with him. Make him have a reason to share this experience with the whole world.",
      button: "See our services"
    },
    thirdSection: {
      title:
        "Do you want to attract customers and turn them into the ambassadors of your brand?",
      subtitle:
        "You can achieve it with properly designed and created packaging.",
      text1:
        "To achieve success, packaging must do more than just speak to our intellect. It must look and be seen as more splashy, ecological, or sometimes just funnier than the competition, and bring to mind associations with the brand's main values at the first glance.",
      text2: "You have two options.",
      text3:
        "You can spend money on packaging that will only be used to deliver your product to the customer, or order packaging that apart from its obvious function, will do something extra for your gastronomic business.",
      text4:
        "If you want to be a leader, you surely know which option to choose.",
      text5: "We are here to help you with this.",
      button: "Ask for a free package visualization"
    },
    forthSection: {
      title:
        "Get inspired and learn about interesting stories of our customers",
      button: "See more case studies"
    },
    finalSection: {
      title: "Fastpack",
      subtitle:
        "We manufacture packaging made of paper and cardboard with print.",
      listTitle: "Learn about our products:",
      list: [
        {
          name: "Block bottom sos bags",
          link: "block-bottom-sos-bags"
        },
        {
          name: "Takeaway bags for gastronomy",
          link: "takeaway-bags-for-gastronomy"
        },
        {
          name: "Bakery bags",
          link: "backer-bags"
        },
        {
          name: "Paper boxes",
          link: "paper-boxes"
        },
        {
          name: "Cake boxes",
          link: "sweet-boxes"
        },
        {
          name: "Pizza boxes",
          link: "pizza-boxes"
        },
        {
          name: "Sushi boxes 'Box4Sushi'",
          link: "sushi-boxes"
        },
        {
          name: "Paper trays",
          link: "paper-trays"
        },
        {
          name: "Wrap papers and paper pockets",
          link: "wrapping-paper"
        },
        {
          name: "Paper napkins with logo",
          link: "paper-napkins"
        },
        {
          name: "Stickers and Labels",
          link: "sticker-labels"
        },
        {
          name: "Donut boxes",
          link: "donut-package"
        }
      ],
      summary: [
        "Above products are just the most important items in the wide product range of our production.",
        "Do you need unique or unusual products made of paper or cardboard? ",
        "We will make them specially for you.",
        "Contact us, tell us what you need and attach your logo."
      ],
      button: "Order visualizations of your packages ",
      final: "Let's take your gastronomic business to the next level."
    }
  },
  dk: {
    mainSection: {
      title1: "Velkommen",
      title2: "til Fastpack!",
      text:
        "Vi producerer specialfremstillet pap og papiremballage til fødevarer og restauranter. Hvis du er på udkik efter take-away emballage til din restaurant, bar, food truck, cafe eller butik, så er du havnet det rette sted.",
      button: "Se vores tilbud"
    },
    secondSection: {
      title1: "Det er bare emballage...",
      title2: "Eller er det mere end det?",
      text1a:
        "Hvordan ser dine kunder på dit brand ud fra et emballage perspektiv? Se blot på de store mærker og brands, hvor vigtig en simpel eller umiddelbar emballage kan være.",
      text1b:
        "Nye popkulturer vinder frem, hvilket har gjort, at informationer kan deles til alle 4 verdenshjørner i løbet af ingen tid.",
      text2a:
        "Smartphones har næsten ligefrem overtaget mange folks dagligdagsliv og gøren, hvilket har gjort folk meget glade for at dele deres holdninger, billeder og andre informationer med resten af verden – inklusiv deres gastronomiske oplevelser. Næsten alle er på Facebook, Instagram og andre sociale meder og ”deler” gerne.",
      text2b:
        "Dagens forbrugere ser eller bliver ofte opmærksomme på produkter og services, gennem disse medier. Online anmeldelser og anbefalinger er i dag et af de vigtigste markedsføringsværktøjer. ",
      text2c:
        "Det er derfor at det er så vigtigt, at positivt overraske sine kunder, opbygge en god og og forhåbentlig lang relation til dem. Giv dem en grund til at dele denne oplevelse med resten af verden.",
      button: "Se hvad vi kan tilbyde"
    },
    thirdSection: {
      title:
        "Vil du tiltr ække kunderne og gøre dem til dit brands ”ambassadører”?",
      subtitle:
        "Det kan du nemt gøre ved at have det rette designede emballage!",
      text1:
        "For at det skal lykkes, skal emballagen tale til mere end bare vores intellekt. Det skal også se mere stilrent, farvet, økologisk eller somme tiden sjovere ud end konkurrenternes og samtidig helst også minde folk om mærkets kærneværdier eller koncept. ",
      text2: "Der er 2 måder at gøre det på.",
      text3:
        "Enten kan du bruge penge på emballage som ”kun” bruges til at levere produktet til kunden. Eller også kan emballagen bruge til mere end det indlysende, at gøre meget mere for din butik. ",
      text4:
        "Hvis du vil være en af dem som går forrest, så ved du nok hvad der er vejen frem.",
      text5: "Vi er her for at hjælpe med dette.",
      button: "Modtag et gratis grafisk udkast"
    },
    forthSection: {
      title:
        "Bliv inspireret og l<span>æ</span>r mere om vores tidligere kunders succes.",
      button: "Se tidligere projekter"
    },
    finalSection: {
      title: "Fastpack",
      subtitle: "Vi producerer logo-printet emballage lavet af papir og pap.",
      listTitle: "Find ud af mere om vores produkter nedenfor:",
      list: [
        {
          name: "SMÅ KLODSBUNDSPOSER",
          link: "block-bottom-sos-bags"
        },
        {
          name: "TAKE AWAY POSER TIL RESTAURANTER",
          link: "takeaway-bags-for-gastronomy"
        },
        {
          name: "BAGERPOSER",
          link: "backer-bags"
        },
        {
          name: "BOKSE OG ÆSKER",
          link: "paper-boxes"
        },
        {
          name: "KAGEÆSKER",
          link: "sweet-boxes"
        },
        {
          name: "PIZZABAKKER OG ÆSKER",
          link: "pizza-boxes"
        },
        {
          name: "SUSHI BOKSE 'Box4Sushi'",
          link: "sushi-boxes"
        },
        {
          name: "PØLSEBAKKER",
          link: "paper-trays"
        },
        {
          name: "SANDWICHPAPIR OG BURGERLOMMER",
          link: "wrapping-paper"
        },
        {
          name: "PAPKRUS",
          link: "paper-cup"
        },
        {
          name: "PAPBÆGRER OG SUPPEBEHOLDERE ",
          link: "paper-bowls"
        },
        {
          name: "SERVIETTER MED LOGO",
          link: "paper-napkins"
        },
        {
          name: "RETAIL PAP/KARTON KASSER OG POS MATERIALER",
          link: "retail-packaging"
        },
        {
          name: "KLISTERMÆRKER OG LABELS",
          link: "sticker-labels"
        },
        {
          name: "ANDRE PRODUKTER",
          link: "complimentary-products"
        },
        {
          name: "ÆSKER TIL DONUTS",
          link: "donut-package"
        }
      ],
      summary: [
        "Ovenstående produkter er kun et lille udkast af de produkter som vi producerer.",
        "Har du brug for et unikt eller anderledes papir eller pap-produkt?",
        "Vi producerer det meste!",
        "Kontakt os, og skriv hvad du har brug for, samt vedhæft dit logo."
      ],
      button: "Modtag et grafisk udkast af din emballage ",
      final: "Lad os tage dit gastronomiske udtryk til et andet niveau. "
    }
  },
  es: {
    mainSection: {
      title1: "Bienvenido",
      title2: "a Fastpack!",
      text:
        "Somos fabricantes de envases personalizados de papel y cartón con estampado para el sector gastronómico. Si estás buscando envases take-away para tu restaurante, bar, food truck o cafetería, estás en el lugar adecuado.",
      button: "CONSULTA NUESTRA OFERTA"
    },
    secondSection: {
      title1: "ÓLO UN ENVASE...",
      title2: "O QUIZÁS ALGO MÁS?",
      text1a:
        "¿Sabes cómo los clientes perciben tu marca a través del prisma del envase? Basta examinar las marcas líderes a nivel mundial para darse cuenta que el envase, un elemento que parecería insignificante, en realidad, es él de la estrategia de una empresa.",
      text1b:
        "Una nueva cultura pop se está formando lo que ha provocado que la información llega casi a todos los rincones del mundo a la velocidad de la luz. Los smartphones han tomado las riendas de nuestras vidas. Nos acompañan a diario.",
      text2a:
        "Estamos abiertos a compartir nuestras opiniones, fotos o todo tipo de la información con el mundo entero. Casi todos ya están presentes en Facebokok, Instagram o otras redes sociales.",
      text2b:
        "El consumidor moderno percibe productos y servicios a través del prisma de esta información. Las referencias y recomendaciones en línea son actualmente una de las herramientas de marketing más poderosas.",
      text2c:
        "Por eso, resulta tan importante captar la atención del cliente, sorprenderle positivamente y construir con él una relación a largo plazo. Hacer que tenga una razón para compartir esta experiencia con el resto del mundo.",
      button: "CONSULTA NUESTROS SERVICIOS"
    },
    thirdSection: {
      title:
        "¿Quieres atraer clientes y convertirlos en los embajadores de tu marca?",
      subtitle:
        "Puedes lograrlo gracias a un envase personalizado de diseño y fabricación adecuados.",
      text1:
        "Para lograr el éxito, un envase tiene que aportar más que un simple hecho de serlo. Su presencia tiene que dar impresión de más eficaz, ecológica o, a veces, más divertida que la de los envases de la competencia. A primera vista, debería reflejar los valores fundamentales de una marca.",
      text2: "Tienes dos opciones.",
      text3:
        "Puedes gastar tu dinero en un envase que simplemente será un método de entregar tu producto al cliente, o puedes  encargar un envase que además de su función obvia, aportará un valor añadido a tu negocio gastronómico.",
      text4: "Si quieres ser un líder, ya sabes qué opción elegir.",
      text5: "Estamos aquí para ayudarte.",
      button: "SOLICITA VISUALIZACIÓN DE ENVASE GRATUITA"
    },
    forthSection: {
      title:
        "INSPÍRATE Y DESCUBRE LAS HISTORIAS INTERESANTES DE NUESTROS CLIENTES",
      button: "DESCUBRE OTRAS HISTORIAS INTERESANTES"
    },
    finalSection: {
      title: "Fastpack",
      subtitle:
        "Fabricamos envases personalizados de papel y cartón con estampados.",
      listTitle: "Decubre nuestros productos:",
      list: [
        {
          name: "Bolsas de papel fondo cuadrado",
          link: "block-bottom-sos-bags"
        },
        {
          name: "Bolsas de papel para llevar comid",
          link: "takeaway-bags-for-gastronomy"
        },
        {
          name: "Bolsas para panaderías",
          link: "backer-bags"
        },
        {
          name: "Cajas de papel para llevar",
          link: "paper-boxes"
        },
        {
          name: "Cajas de panadería",
          link: "sweet-boxes"
        },
        {
          name: "Cajas de pizza",
          link: "pizza-boxes"
        },
        {
          name: "Cajas de papel para sushi 'Box4Sushi'",
          link: "sushi-boxes"
        },
        {
          name: "Bandejas de papel",
          link: "paper-trays"
        },
        {
          name: "Papel y mangas para cocinar",
          link: "wrapping-paper"
        },
        {
          name: "Vasos de papel con estampado",
          link: "paper-cups"
        },
        {
          name: "Boles de papel y envases para sopas",
          link: "paper-bowls"
        },
        {
          name: "Servilletas de papel con logo",
          link: "paper-napkins"
        },
        {
          name: "Cajas para venta minorista y materiales POS",
          link: "retail-packaging"
        },
        {
          name: "Adhesivos y etiquetas adhesivasStickers and Labels",
          link: "sticker-labels"
        },
        {
          name: "Productos complementarios",
          link: "complimentary-products"
        },
        {
          name: "Cajas para donas",
          link: "donut-package"
        }
      ],
      summary: [
        "Los productos arriba mencionados son solo las más importantes de la amplia gama de nuestra oferta.",
        "¿Necesitas productos únicos o atípicos hechos de papel o cartón? Los fabricaremos especialmente para ti.",
        "Contáctanos  a través del formulario y cuéntanos qué necesitas."
      ],
      button: "SOLICITA VISUALIZACIÓN DE TUS ENVASES",
      final: "Vamos a llevar tu negocio gastronómico al siguiente nivel."
    }
  },
  de: {
    mainSection: {
      title1: "Willkommen",
      title2: "bei Fastpack!",
      text:
        "Wir produzieren kundenspezifisch bedruckte Papier- und Kartonverpackungen für die Gastronomie. Wenn Sie nach einer Take-Away-Verpackung für Ihr Restaurant, Ihre Bar, Ihren Imbisswagen oder Ihr Café suchen, dann sind Sie bei uns genau richtig.",
      button: "SEHEN SIE UNSER ANGEBOT"
    },
    secondSection: {
      title1: "ES IST BLOSS EINE VERPACKUNG ...",
      title2: "ODER VIELLEICHT IST ES DOCH ETWAS MEHR?",
      text1a:
        "Es formt sich eine neue Popkultur, die Informationen mit Lichtgeschwindigkeit in fast jede Ecke des Planeten dringen lässt. Smartphones haben unser Leben erobert und begleiten uns jeden Tag.",
      text1b:
        "Wir teilen gerne unsere Meinungen, Fotos und Hunderte verschiedener Informationen mit der ganzen Welt zu teilen. Fast jeder ist bereits auf Facebook, Instagram oder anderen Social Media präsent.",
      text2a:
        "Der heutige Verbraucher betrachtet Produkte und Dienstleistungen unter dem Einfluss dieser Informationen. Online-Empfehlungen sind derzeit eines der leistungsstärksten Marketing-Tools.",
      text2b:
        "Deshalb ist es so wichtig, den Kunden positiv zu überraschen, sein Interesse zu gewinnen und eine Langzeitbeziehung zu ihm zu bauen.",
      text2c: "Erfahrung mit der ganzen Welt zu teilen.",
      button: "PRÜFEN SIE UNSERE DIENSTLEISTUNGEN"
    },
    thirdSection: {
      title:
        "Sie möchten Kunden gewinnen und sie zu Botschaftern. Ihrer Marke machen?",
      subtitle:
        "Sie können dies mit richtig entworfenen und gefertigten Verpackungen erreichen.",
      text1:
        "Um erfolgreich zu sein, muss die Verpackung mehr als nur unser Intellekt ansprechen. Es muss glamouröser, ökologischer, oder manchmal ulkiger als bei der Konkurrenz aussehen und so empfunden werden. Auf den ersten Blick soll die Verpackung die Grundwerte der Marke in den Sinn rufen.",
      text2: "Sie haben zwei Möglichkeiten.",
      text3:
        "Sie können Geld für Verpackungen ausgeben, die Ihr Produkt lediglich an den Kunden liefert, oder Verpackungen bestellen, die außer ihrer offensichtlichen Funktion etwas Besonderes für Ihr gastronomisches Geschäft tun.",
      text4:
        "Wenn Sie ein Marktführer sein wollen, wissen Sie bereits genau, welchen Weg Sie wählen müssen.",
      text5: "Wir sind hier da, um Ihnen dabei zu helfen.",
      button: "BESTELLEN SIE EINE KOSTENLOSE VISUALISIERUNG DER VERPACKUNGEN"
    },
    forthSection: {
      title:
        "LASSEN SIE SICH INSPIRIEREN UND LERNEN SIE DIE INTERESSANTESTEN GESCHICHTEN UNSERER KUNDEN KENNEN",
      button: "MEHR INTERESSANTE GESCHICHTEN SEHEN"
    },
    finalSection: {
      title: "Fastpack",
      subtitle:
        "Wir produzieren ökologische Verpackungen aus Papier und Pappe mit Aufdruck.",
      listTitle: "Lernen Sie unsere Produkte kennen:",
      list: [
        {
          name: "Kleine Blockbeutel",
          link: "block-bottom-sos-bags"
        },
        {
          name: "Papiertüten zum Mitnehmen für die Gastronomie",
          link: "takeaway-bags-for-gastronomy"
        },
        {
          name: "Bäckertüten",
          link: "backer-bags"
        },
        {
          name: "Papierboxen zum Mitnehmen",
          link: "paper-boxes"
        },
        {
          name: "Süßwarenboxen",
          link: "sweet-boxes"
        },
        {
          name: "Pizzaschachteln",
          link: "pizza-boxes"
        },
        {
          name: "Sushi-Papierboxen 'Box4Sushi'",
          link: "sushi-boxes"
        },
        {
          name: "Papptabletts",
          link: "paper-trays"
        },
        {
          name: "Gastronomisches Papier und gastronomische Taschen",
          link: "wrapping-paper"
        },
        {
          name: "Pappbecher mit Aufdruck",
          link: "paper-cups"
        },
        {
          name: "Papierschalen und Suppenbehälter",
          link: "paper-bowls"
        },
        {
          name: "Papierservietten mit Logo",
          link: "paper-napkins"
        },
        {
          name: "Einzelhandelsboxen und POS-Zubehör",
          link: "retail-packaging"
        },
        {
          name: "Selbstklebende Aufkleber und Etiketten",
          link: "sticker-labels"
        },
        {
          name: "Ergänzende Produkte",
          link: "complimentary-products"
        },
        {
          name: "Donut boxen",
          link: "donut-package"
        }
      ],
      summary: [
        "Die oben genannten Produkte sind nur die wichtigsten Artikel im breiten Sortiment unserer Produktion.",
        "Brauchen Sie einzigartige oder ungewöhnliche Papier- bzw. Kartonprodukte? ",
        "Wir machen sie eigens für Sie.",
        "Kontaktieren Sie uns über das Kontaktformular und beschreiben Sie, was Sie brauchen."
      ],
      button: "BESTELLEN SIE EINE VISUALISIERUNG IHRER EIGENEN VERPACKUNGEN",
      final:
        "Gemeinsam bringen wir Ihr gastronomisches Geschäft auf ein höheres Niveau."
    }
  },
  fr: {
    mainSection: {
      title1: "Bienvenue",
      title2: "à Fastpack !",
      text:
        "Nous fabriquons des emballages en papier et carton personnalisés pour la gastronomie. Si vous recherchez des emballages à emporter pour votre restaurant, bar, food truck ou café alors vous êtes au bon endroit.",
      button: "CONSULTEZ NOTRE OFFRE"
    },
    secondSection: {
      title1: "CE N'EST QU'UN EMBALLAGE...",
      title2: "OU PEUT-ÊTRE QUELQUE CHOSE DE PLUS ?",
      text1a:
        "Savez-vous comment les clients perçoivent votre marque à travers le prisme de l'emballage ? Il suffit d'examiner les grandes marques mondiales se rendre compte de l'importance d'un emballage apparemment insignifiant en tant qu'élément de la stratégie.",
      text1b:
        "Une nouvelle culture est en train de se former, faisant venir l’information à la vitesse de la lumière presque tous les coins de la planète. Les smartphones  ont pris le contrôle de nos vies et ils nous accompagnent chaque jour.",
      text2a:
        "Nous sommes heureux de partager des opinions, des photos et des centaines d'informations différentes avec le monde entier. Presque tout le monde est déjà présent sur Facebook, Instagram ou autres des médias sociaux.",
      text2b:
        "Le consommateur d'aujourd'hui perçoit les produits et services à travers le prisme de ces informations. Les commandes et recommandations en ligne sont actuellement l'un des outils de marketing les plus puissants.",
      text2c:
        "Par conséquent, il est si important de surprendre positivement et d’intéresser le client,  construire une relation avec lui. Donnez-lui une raison de partager cette expérience avec le monde entier.",
      button: "CONSULTEZ NOS SERVICES"
    },
    thirdSection: {
      title:
        "Vous souhaitez attirer des clients et les transformer en vos ambassadeurs de marque ?",
      subtitle:
        "Vous pouvez y parvenir avec des emballages bien conçus et fabriqués.",
      text1:
        "Pour réussir, l'emballage doit faire plus que parler à notre intellect. L'emballage doit paraître et être perçu comme plus séduisant, plus vert, ou parfois plus amusant que la concurrence. À première vue, l'emballage doit évoquer des associations avec les principales valeurs de la marque.",
      text2: "Vous avez deux options.",
      text3:
        "Vous pouvez dépenser de l'argent sur des emballages qui ne livreront votre produit qu'au client, ou commandez un emballage qui, en plus de sa fonction évidente, fera quelque chose de plus pour votre entreprise gastronomique.",
      text4:
        "Si vous voulez être un leader, vous savez sûrement quelle option choisir.",
      text5: "Nous sommes là pour vous aider.",
      button: "COMMANDER UNE VISUALISATION GRATUITE"
    },
    forthSection: {
      title: "INSPIREZ ET DÉCOUVREZ LES HISTOIRES INTÉRESSANTES DE NOS CLIENTS",
      button: "EXPLOREZ D'AUTRES HISTOIRES INTÉRESSANTES"
    },
    finalSection: {
      title: "Fastpack",
      subtitle:
        "Tout cela grâce à Fastpack, nous produisons des emballages écologiques à partir de papier et de carton imprimés.",
      listTitle: "Découvrez nos produits :",
      list: [
        {
          name: "Petits sacs SOS",
          link: "block-bottom-sos-bags"
        },
        {
          name: "Sacs en papier à emporter pour la gastronomie",
          link: "takeaway-bags-for-gastronomy"
        },
        {
          name: "Sacs de boulangerie",
          link: "backer-bags"
        },
        {
          name: "Boîtes en papier à emporte",
          link: "paper-boxes"
        },
        {
          name: "Boîtes à gâteaux",
          link: "sweet-boxes"
        },
        {
          name: "Boîtes à pizza",
          link: "pizza-boxes"
        },
        {
          name: "Boîtes à sushi en papier 'Box4Sushi'",
          link: "sushi-boxes"
        },
        {
          name: "Bacs à papier",
          link: "paper-trays"
        },
        {
          name: "Papiers d'emballage et pochettes en papier",
          link: "wrapping-paper"
        },
        {
          name: "Gobelets en papier avec une impression",
          link: "paper-cups"
        },
        {
          name: "Bols en papier et récipients à soupe",
          link: "paper-bowls"
        },
        {
          name: "Serviettes en papier avec logo",
          link: "paper-napkins"
        },
        {
          name: "Boîtes pour la vente au détail et matériel de point de vente",
          link: "retail-packaging"
        },
        {
          name: "Autocollants et étiquettes auto-adhésifs",
          link: "sticker-labels"
        },
        {
          name: "Produits complémentaires",
          link: "complimentary-products"
        },
        {
          name: "Donut boxes",
          link: "donut-package"
        }
      ],
      summary: [
        "Les produits ci-dessus ne sont que les articles les plus importants dans la large gamme de notre production.",
        "Vous avez besoin de produits uniques ou inhabituels en papier ou en carton ?",
        "Nous les fabriquerons spécialement pour vous.",
        "Contactez-nous via le formulaire de contact et décrivez ce dont vous avez besoin."
      ],
      button: "COMMANDEZ LA VISUALISATION DE VOTRE PROPRE EMBALLAGE",
      final:
        "Faisons passer votre entreprise gastronomique au niveau supérieur."
    }
  }
};
