export default {
  en: {
    packaging_la_neta_s1: {
      name_photo: "Packaging_La-Neta-S1",
      meta_tag: "Closed paper box and paper tray with logo La Neta.",
      description: "Closed box and tray made of brown paper with La Neta print."
    },
    fastpack_s2: {
      name_photo: "Fastpack-S2",
      meta_tag: "Fastpack – producer of paper packaging.",
      description: "Fastpack – producer of paper packaging."
    },
    pizza_box_petri_s3: {
      name_photo: "Pizza_box_Petri-S3",
      meta_tag: "Black pizza box with a white Petri logo.",
      description: "Black pizza box with a white Petri logo."
    },
    pizza_box_frankies_s4: {
      name_photo: "Pizza_box_Frankies-S4",
      meta_tag: "Pizza box with Frankies logo.",
      description: "A pink pizza box with the Frankies white logo."
    },
    paper_pizze_box_s5: {
      name_photo: "paper-pizze-box-NR45",
      meta_tag: "Pizza box.",
      description: "Corsa Pizza"
    },
    gelato_box: {
      name_photo: "gelato-box",
      meta_tag: "Gelato Di Natura",
      description: "Gelato Di Natura"
    },
    sushi_box_sosushi_s7: {
      name_photo: "Sushi_box_SoSushi-S7",
      meta_tag: "Paper box for sushi with the So Sushi logo",
      description:
        "Elegant, black sushi box with window. Print with the So Sushi company logo and golden slogan."
    }
  },
  dk: {
    packaging_la_neta_s1: {
      name_photo: "Emballage_La-Neta-S1",
      meta_tag: "Lukket tacoboks og papirbakke med La Neta logo",
      description: "Lukket tacoboks og papirbakke med  La Neta logo"
    },
    fastpack_s2: {
      name_photo: "Fastpack-S2",
      meta_tag: "Fastpack – emballageproducent.",
      description: "Fastpack – emballageproducent."
    },
    pizza_box_petri_s3: {
      name_photo: "Pizzaæske_Petri-S3",
      meta_tag: "Pizzaæske med Skt. Petri logo.",
      description: "Sort pizzaæske med hvidt Skt. Petri logo."
    },
    pizza_box_frankies_s4: {
      name_photo: "Pizzaæske_Frankies-S4",
      meta_tag: "Pizzaæske med Frankies logo.",
      description: "En pink pizzaæske med Frankies logo."
    },
    paper_pizze_box_s5: {
      name_photo: "paper-pizze-box-NR45",
      meta_tag: "Pizza box.",
      description: "Corsa Pizza"
    },
    gelato_box: {
      name_photo: "gelato-box",
      meta_tag: "Gelato Di Natura",
      description: "Gelato Di Natura"
    },
    sushi_box_sosushi_s7: {
      name_photo: "Sushi_boks_SoSushi-S7",
      meta_tag: "Sushibakke/sushiboks med So Sushi-logo",
      description:
        "Elegant, sort sushiboks med vindue. Printet med So Sushis firmalogo og gyldent slogan."
    }
  },
  es: {
    packaging_la_neta_s1: {
      name_photo: "Packaging_La-Neta-S1",
      meta_tag: "Closed paper box and paper tray with logo La Neta.",
      description: "Closed box and tray made of brown paper with La Neta print."
    },
    fastpack_s2: {
      name_photo: "Fastpack-S2",
      meta_tag: "Fastpack – producer of paper packaging.",
      description: "Fastpack – producer of paper packaging."
    },
    pizza_box_petri_s3: {
      name_photo: "Pizza_box_Petri-S3",
      meta_tag: "Black pizza box with a white Petri logo.",
      description: "Black pizza box with a white Petri logo."
    },
    pizza_box_frankies_s4: {
      name_photo: "Pizza_box_Frankies-S4",
      meta_tag: "Pizza box with Frankies logo.",
      description: "A pink pizza box with the Frankies white logo."
    },
    paper_pizze_box_s5: {
      name_photo: "paper-pizze-box-NR45",
      meta_tag: "Pizza box.",
      description: "Corsa Pizza"
    },
    gelato_box: {
      name_photo: "gelato-box",
      meta_tag: "Gelato Di Natura",
      description: "Gelato Di Natura"
    },
    sushi_box_sosushi_s7: {
      name_photo: "Sushi_box_SoSushi-S7",
      meta_tag: "Paper box for sushi with the So Sushi logo",
      description:
        "Elegant, black sushi box with window. Print with the So Sushi company logo and golden slogan."
    }
  },
  de: {
    packaging_la_neta_s1: {
      name_photo: "Packaging_La-Neta-S1",
      meta_tag: "Closed paper box and paper tray with logo La Neta.",
      description: "Closed box and tray made of brown paper with La Neta print."
    },
    fastpack_s2: {
      name_photo: "Fastpack-S2",
      meta_tag: "Fastpack – producer of paper packaging.",
      description: "Fastpack – producer of paper packaging."
    },
    pizza_box_petri_s3: {
      name_photo: "Pizza_box_Petri-S3",
      meta_tag: "Black pizza box with a white Petri logo.",
      description: "Black pizza box with a white Petri logo."
    },
    pizza_box_frankies_s4: {
      name_photo: "Pizza_box_Frankies-S4",
      meta_tag: "Pizza box with Frankies logo.",
      description: "A pink pizza box with the Frankies white logo."
    },
    paper_pizze_box_s5: {
      name_photo: "paper-pizze-box-NR45",
      meta_tag: "Pizza box.",
      description: "Corsa Pizza"
    },
    gelato_box: {
      name_photo: "gelato-box",
      meta_tag: "Gelato Di Natura",
      description: "Gelato Di Natura"
    },
    sushi_box_sosushi_s7: {
      name_photo: "Sushi_box_SoSushi-S7",
      meta_tag: "Paper box for sushi with the So Sushi logo",
      description:
        "Elegant, black sushi box with window. Print with the So Sushi company logo and golden slogan."
    }
  },
  fr: {
    packaging_la_neta_s1: {
      name_photo: "Packaging_La-Neta-S1",
      meta_tag: "Closed paper box and paper tray with logo La Neta.",
      description: "Closed box and tray made of brown paper with La Neta print."
    },
    fastpack_s2: {
      name_photo: "Fastpack-S2",
      meta_tag: "Fastpack – producer of paper packaging.",
      description: "Fastpack – producer of paper packaging."
    },
    pizza_box_petri_s3: {
      name_photo: "Pizza_box_Petri-S3",
      meta_tag: "Black pizza box with a white Petri logo.",
      description: "Black pizza box with a white Petri logo."
    },
    pizza_box_frankies_s4: {
      name_photo: "Pizza_box_Frankies-S4",
      meta_tag: "Pizza box with Frankies logo.",
      description: "A pink pizza box with the Frankies white logo."
    },
    paper_pizze_box_s5: {
      name_photo: "paper-pizze-box-NR45",
      meta_tag: "Pizza box.",
      description: "Corsa Pizza"
    },
    gelato_box: {
      name_photo: "gelato-box",
      meta_tag: "Gelato Di Natura",
      description: "Gelato Di Natura"
    },
    sushi_box_sosushi_s7: {
      name_photo: "Sushi_box_SoSushi-S7",
      meta_tag: "Paper box for sushi with the So Sushi logo",
      description:
        "Elegant, black sushi box with window. Print with the So Sushi company logo and golden slogan."
    }
  }
};
