import React, { useContext } from "react";
import styles from "./FinalSection.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";
import classNames from "classnames";
import { langPrefix } from "../../../shared/scripts";
import { Link } from "gatsby";
import { HOME_PAGE_CLICKED_PRODUCT, CHANGE_PAGE } from "../../../types";

const FinalSection = ({
  content: { title, subtitle, listTitle, summary, button, final },
  products,
  lang,
  globalDispatchContext
}) => {
  const dispatch = useContext(globalDispatchContext);

  const onPageChange = (e, page) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };

  const onSubProductPageChange = page => {
    dispatch({ type: HOME_PAGE_CLICKED_PRODUCT, payload: page });
  };

  return (
    <Container className={styles.self}>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2
            className={classNames("triangle", "tsmall", "yellow", styles.title)}
          >
            {title}
          </h2>
          <p>{subtitle}</p>
        </Col>
      </Row>
      <Row className={styles.links}>
        <Col className={classNames(styles.section, styles.first)}>
          <p>{listTitle}</p>
          <ul>
            {products.map(i => (
              <li key={i.link}>
                <Link
                  onClick={event => {
                    onSubProductPageChange(i.link);
                    onPageChange(event, "products");
                  }}
                  to={`${i.link}`}
                >
                  {i.text}
                </Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row className={styles.second}>
        <Col md={{ span: 6, offset: 3 }}>
          {summary.map((line, i) => (
            <p key={`final-section-${i * 100}`}>{line}</p>
          ))}
        </Col>
      </Row>
      <Row md={{ span: 6, offset: 3 }} className="justify-content-center">
        <ButtonPrimary
          className={styles.button}
          ń
          variant="outline-primary"
          to="contact"
          language={lang}
        >
          {button}
        </ButtonPrimary>{" "}
      </Row>
      <br />
      <Row md={{ span: 6, offset: 3 }} className="justify-content-center">
        <p>
          <b>{final}</b>
        </p>
      </Row>
    </Container>
  );
};

export default FinalSection;
