import React from "react";
import styles from "./SecondSection.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";
import classNames from "classnames";
import { getImgUrl } from "../../../shared/scripts";

const SecondSection = ({
  copy1,
  copy2,
  button,
  title,
  title2,
  lang,
  allFile,
  images
}) => {
  return (
    <Container className={styles.self}>
      <Row>
        <Col xs={12}>
          <h2 className="triangle tsmall yellow">
            {title}
            <br />
            {title2}
          </h2>
        </Col>
      </Row>
      <Row className={styles.first}>
        <Col md={6} className={styles.image}>
          <Image
            src={getImgUrl(images[0].name_photo, allFile)}
            title={images[0].meta_tag}
            alt={images[0].description}
            fluid
          />
        </Col>
        <Col
          className={classNames(styles.section, styles.first)}
          md={6}
          dangerouslySetInnerHTML={{ __html: copy1 }}
        />
      </Row>
      <Row className={styles.second}>
        <Col
          md={{ span: 5, offset: 2 }}
          className={classNames(styles.section)}
          dangerouslySetInnerHTML={{ __html: copy2 }}
        />
        <Col md={5} className={styles.image}>
          <Image
            src={getImgUrl(images[1].name_photo, allFile)}
            title={images[1].meta_tag}
            alt={images[1].description}
            fluid
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <ButtonPrimary variant="outline-primary" to="about" language={lang}>
          {button}
        </ButtonPrimary>{" "}
      </Row>
    </Container>
  );
};

export default SecondSection;
