import React from "react";
import styles from "./ThirdSection.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";
import classNames from "classnames";
import { getImgUrl } from "../../../shared/scripts";

const ThirdSection = ({
  title,
  text1,
  copy,
  footer,
  button,
  lang,
  allFile,
  image
}) => {
  return (
    <Container className={styles.self}>
      <Row>
        <Col
          className={styles.title}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
        >
          <h2 className="triangle tsmall yellow">{title}</h2>
          <p>{text1}</p>
        </Col>
      </Row>
      <Row className={styles.first}>
        <Col md={6} className={styles.image}>
          <Image
            src={getImgUrl(image.name_photo, allFile)}
            title={image.meta_tag}
            alt={image.description}
            fluid
          />
        </Col>
        <Col
          className={classNames(styles.section, styles.first)}
          md={6}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </Row>
      <Row>
        <Col
          className={styles.final}
          md={{ span: 8, offset: 2 }}
          dangerouslySetInnerHTML={{ __html: footer }}
        />
      </Row>
      <Row className="justify-content-center">
        <ButtonPrimary variant="outline-primary" to="contact" language={lang}>
          {button}
        </ButtonPrimary>{" "}
      </Row>
    </Container>
  );
};

export default ThirdSection;
