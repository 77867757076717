import React from "react";
import homePageContent from "../../shared/pages/home";
import { Container } from "react-bootstrap";
import {
  GlobalStateContext,
  GlobalDispatchContext
} from "../../context/GlobalContextProvider";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import ForthSection from "./ForthSection";
import FinalSection from "./FinalSection";
import { graphql, useStaticQuery } from "gatsby";
import MainCarousel from "./MainCarousel";
import dataPage from "./dataHomePage";

const HomePage = ({ content }) => {
  const { language } = React.useContext(GlobalStateContext);
  const home = content.home;
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "home" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  );
  const { forthSection, finalSection } = homePageContent[language];

  return (
    <>
      <MainCarousel
        content={content.content}
        button={home.seeOfferButton}
        lang={language}
      />
      <Container>
        <SecondSection
          copy1={home.onlyPackagingCopy}
          copy2={home.onlyPackagingMoreCopy}
          title={home.itsOnlyPackaging}
          title2={home.orMaybeSomethingMore}
          button={home.seeServicesButton}
          lang={language}
          allFile={allFile}
          images={[
            dataPage[language].packaging_la_neta_s1,
            dataPage[language].fastpack_s2
          ]}
        />
        <ThirdSection
          title={home.secondSectionTitle}
          text1={home.secondSectionText1}
          copy={home.secondSectionCopy}
          footer={home.secondSectionFooter}
          button={home.askForVizualizationButton}
          lang={language}
          allFile={allFile}
          image={dataPage[language].pizza_box_petri_s3}
        />
        <ForthSection
          content={forthSection}
          lang={language}
          allFile={allFile}
          images={[
            dataPage[language].pizza_box_frankies_s4,
            dataPage[language].paper_pizze_box_s5,
            dataPage[language].gelato_box,
            dataPage[language].sushi_box_sosushi_s7
          ]}
        />
        <FinalSection
          globalDispatchContext={GlobalDispatchContext}
          content={finalSection}
          products={content.home.products}
          lang={language}
        />
      </Container>
    </>
  );
};

export default HomePage;
