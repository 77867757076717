import React from "react";
import { Link } from "gatsby";
import styles from "./ForthSection.module.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";
import { langPrefix, getImgUrl } from "../../../shared/scripts";

const ForthSection = ({
  content: { title, button },
  lang,
  allFile,
  images
}) => {
  return (
    <Container
      className={styles.self}
      style={{
        backgroundImage: `url(${getImgUrl("triangleBackground", allFile)})`
      }}
    >
      <Row>
        <Col md={8}>
          <h2 className="triangle tsmall yellow">
            <span
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            ></span>
          </h2>
        </Col>
      </Row>
      <Row className={styles.first}>
        <Col xs={6} md={{ span: 4, offset: 1 }} className={styles.image1}>
          <Link
            className={styles.link}
            to={`${langPrefix(lang)}case-studies#frankies`}
          >
            <h4 className={"item bulletcyan"}>Frankies</h4>
          </Link>
          <Image
            src={getImgUrl(images[0].name_photo, allFile)}
            title={images[0].meta_tag}
            alt={images[0].description}
            fluid
          />
        </Col>
        <Col xs={6} md={{ span: 4, offset: 2 }} className={styles.image2}>
          <Link
            className={styles.link}
            to={`${langPrefix(lang)}case-studies#corsa`}
          >
            <h4 className={"item bulletmagenta"}>Corsa Pizza</h4>
          </Link>
          <Image
            src={getImgUrl(images[1].name_photo, allFile)}
            title={images[1].meta_tag}
            alt={images[1].description}
            fluid
          />
        </Col>
      </Row>
      <Row className={styles.second}>
        <Col xs={6} md={{ span: 4, offset: 2 }} className={styles.image3}>
          <Link
            className={styles.link}
            to={`${langPrefix(lang)}case-studies#gelato`}
          >
            <h4 className={"item bulletyellow"}>Gelato Di Natura</h4>
          </Link>
          <Image
            src={getImgUrl(images[2].name_photo, allFile)}
            title={images[2].meta_tag}
            alt={images[2].description}
            fluid
          />
        </Col>
        <Col xs={6} md={{ span: 4, offset: 2 }} className={styles.image4}>
          <Link
            className={styles.link}
            to={`${langPrefix(lang)}case-studies#sosushi`}
          >
            <h4 className={"item bulletcyan"}>So Sushi</h4>
          </Link>
          <Image
            src={getImgUrl(images[3].name_photo, allFile)}
            title={images[3].meta_tag}
            alt={images[3].description}
            fluid
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <ButtonPrimary
          className={styles.button}
          variant="outline-primary"
          to="case-studies"
          language={lang}
        >
          {button}
        </ButtonPrimary>{" "}
      </Row>
    </Container>
  );
};

export default ForthSection;
